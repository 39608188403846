











































































import Vue from "vue";

export default Vue.extend({
  name: "LiveStream",
  computed: {
    videoURL() {
      return process.env.VUE_APP_LIVE_STREAM_URL;
    },
  },
  methods: {
    hasVideoURL() {
      let url = this.videoURL;
      return url !== undefined && url.length > 0;
    },
  },
});
